import {Box,Heading,Text} from '@chakra-ui/react'
import MeinSEO from '../components/SEO'
import React from 'react'
import Link from '../components/gatsbylink'

export default function Kontakt({location}){
       
return( 
<>


<MeinSEO title="Kontakt" description="Kontaktdaten von K.O.M.M" />

  
<Box pt="10" minHeight="800" margin="auto" width="90vw" px="5vw" maxWidth="1100" >
        
        <Heading variant="title">Kontakt</Heading>
<Text fontSize={["lg","1xl","2xl"]}>in der Naxoshalle:<br/>
Waldschmidtstraße 19<br/>
60316 Frankfurt<br/>
Tel.: 069 – 43 05 47 33<br/>
Fax: 069 – 43 05 47 34<br/>
Karten: 069 – 43 05 47 34<br/>
theater.willypraml@t-online.de</Text>
<Text fontWeight="400"></Text>
<Link to="tickets"><Text _hover={{color:'blue'}} fontSize="xl">&rarr; Ticketverkauf</Text></Link>
</Box>

</>
        )
}
